(() => {

  const getReferralTracking = (tracking = {}, referrer) => {
    let payload = {...tracking};
    let referrerMap = [
      {
        pattern: /^.*(google).*$/,
        params: {
          utm_medium: 'organic'
        }
      },
      {
        pattern: /^.*(bing).*$/,
        params: {
          utm_medium: 'organic'
        }
      },
      {
        pattern: /^.*(yahoo).*$/,
        params: {
          utm_medium: 'organic'
        }
      },
      {
        pattern: /^.*(duckduckgo).*$/,
        params: {
          utm_medium: 'organic'
        }
      },
      {
        pattern: /^.*(facebook|instagram).*$/,
        params: {
          utm_medium: 'social'
        }
      },
      {
        pattern: /^.*(tiktok).*$/,
        params: {
          utm_medium: 'social'
        }
      },
      {
        pattern: /^.*(twitter|pinterest|linkedin|youtube).*$/,
        params: {
          utm_medium: 'social'
        }
      }
    ];

    // check referral data
    if(referrer && referrer !== 'direct') {
      let matched = false;
      for (let i =0; i < referrerMap.length; i++){
        let match = referrer.match(referrerMap[i].pattern)
        if(match) {
          let source = (referrerMap[i].params || {}).utm_source || match[1];
          payload = {...referrerMap[i].params, utm_source: source}
          matched = true;
          break;
        }
      }
      if(!matched) {
        payload = {
          utm_source: referrer.match(/^https?:\/\/(.*?)(?:$|\/)/)[1],
          utm_medium: 'referral'
        }
      }
    }

    return payload;
  }

  const getAdTracking = (tracking, queryParams) => {
    let payload = {...tracking};
    let adQueryMap = {
      gclid: {
        utm_source: 'google',
        utm_medium: 'cpc',
      },
      msclkid: {
        utm_source: 'bing',
        utm_medium: 'cpc',
      },
      vmcid: {
        utm_source: 'yahoo',
        utm_medium: 'cpc',
      },
      ttclid: {
        utm_source: 'tiktok',
        utm_medium: 'cpc',
      },
      fbclid: {
        utm_source: 'facebook',
        utm_medium: 'cpc',
      }
    }

    const adQueryKeys = Object.keys(adQueryMap)
    for(let i = 0; i < adQueryKeys.length; i++) {
      if(queryParams[adQueryKeys[i]]) {
        payload = {...tracking, ...adQueryMap[adQueryKeys[i]]}
        break;
      }
    }

    return payload;
  }

  const getDynamicTracking = (referrer, queryParams) => {
    return getAdTracking(getReferralTracking({}, referrer), queryParams);
  }

  const getTrackingData = () => {
    let queryParams = getQueryParams();
    let referrer = getReferrer();
    const dynamicTracking = getDynamicTracking(referrer, queryParams);
    return {
      fbclid: queryParams.fbclid || '',
      gclid: queryParams.gclid || '',
      msclkid: queryParams.msclkid || '',
      ttclid: queryParams.ttclid || '',
      vmcid: queryParams.vmcid || '',
      referrer,
      landingPage: getCurrentLocation(),
      utm_source: queryParams.utm_source || queryParams.source || dynamicTracking.utm_source || '',
      utm_medium: queryParams.utm_medium || queryParams.medium || dynamicTracking.utm_medium || (referrer === "direct" ? 'direct' : ''),
      utm_keyword: queryParams.utm_term  || queryParams.utm_keyword || queryParams.term || queryParams.keyword ||  '',
      utm_campaign: queryParams.utm_campaign || queryParams.campaign || '',
      utm_content: queryParams.utm_content || queryParams.content || '',
    }
  }

  const waitForMutation = (parentNode, isMatchFunc, handlerFunc, observeSubtree = true, disconnectAfterMatch = true) => {
    let defaultIfUndefined = function (val, defaultVal) {
      return (typeof val === "undefined") ? defaultVal : val;
    };

    observeSubtree = defaultIfUndefined(observeSubtree, false);
    disconnectAfterMatch = defaultIfUndefined(disconnectAfterMatch, false);

    let observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.addedNodes) {
          for (let i = 0; i < mutation.addedNodes.length; i++) {
            let node = mutation.addedNodes[i];
            if (isMatchFunc(node)) {
              handlerFunc(node);
              if (disconnectAfterMatch) observer.disconnect();
            }
          }
        }
      });
    });

    observer.observe(parentNode, {
      childList: true,
      attributes: false,
      characterData: false,
      subtree: observeSubtree
    });
  }

  const getQueryParams = () => {
    let search = location.search.substring(1);
    return search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {}
  }

  const getReferrer = () => {
    return document.referrer !== '' ? document.referrer : 'direct';
  }

  const getCurrentLocation = () => {
    if (s3trackingConfig && s3trackingConfig.pageUrl) {
      return s3trackingConfig.pageUrl + window.location.search
    }
    return window.location.href;
  }

  const setLocalStorage = (payload) => {
    let cache = JSON.parse(localStorage.getItem(localStorageKeyInitial));
    let cacheValid = (cache && (((new Date().getTime() - cache.timestamp) / (1000 * 60 * 60 * 24)) < 60));
    payload.timestamp = new Date().getTime();
    if (!cacheValid) {
      localStorage.setItem(localStorageKeyInitial, JSON.stringify(payload));
      cache = payload;
    }
    if (document.referrer.slice(0, document.location.origin.length) !== document.location.origin) {
      localStorage.setItem(localStorageKey, JSON.stringify(payload));
    } else {
      payload = JSON.parse(localStorage.getItem(localStorageKey));
    }
    //if not s3TrackingLast is set, set it by default
    if(!localStorage.getItem(localStorageKey)) {
      localStorage.setItem(localStorageKey, JSON.stringify(cache)); 
    }
    return { 
      first: cache,
      last: payload
    }
  }

  const addFormField = (form, name, value) => {
    if (name === "timestamp") {
      return
    }
    let input = document.createElement("input");
    let key = name.toLowerCase();
    input.setAttribute("type", "hidden");
    input.setAttribute("name", key);
    input.setAttribute("value", value);
    form.appendChild(input)
  }

  const setFieldOverwrites = (data, parentEl) => {
    if (s3trackingConfig && s3trackingConfig.fieldOverwrites) {
      let keys = Object.keys(s3trackingConfig.fieldOverwrites);
      for (let i = 0; i < keys.length; i++) {
        let updateInput = parentEl.querySelector(`[id='${keys[i]}']`);
        if (updateInput) {
          let keyMapInit = s3trackingConfig.fieldOverwrites[keys[i]].split("_");
          let keyMap = [keyMapInit.slice(0, -1).join("_"), keyMapInit[keyMapInit.length - 1]];
          if (data[keyMap[1]] && data[keyMap[1]][keyMap[0]]) {
            updateInput.value = data[keyMap[1]][keyMap[0]];
          }
        }
      }
    }
  }

  const s3tracking = (elms) => {
    {
      if (elms) {
        //set these so they are accessible as overwrites
        state.first.conversionpage = getCurrentLocation();
        state.last.conversionpage = getCurrentLocation();
        let dataKeys = Object.entries(state);
        for (let y = 0; y < elms.length; y++) {
          if (elms[y].getAttribute("data-s3-tracking")) {
            return;
          }
          elms[y].setAttribute("data-s3-tracking", "true");
          for (let x = 0; x < dataKeys.length; x++) {
            let fieldKeys = Object.keys(dataKeys[x][1]);
            for (let i = 0; i < elms.length; i++) {
              for (let j = 0; j < fieldKeys.length; j++) {
                addFormField(elms[y], fieldKeys[j] + "_" + formKeys[dataKeys[x][0]], dataKeys[x][1][fieldKeys[j]])
              }
            }
          }
          window.setTimeout(() => {
            setFieldOverwrites(state, elms[y]);
          }, 1000)
        }
      }
    }
  }

  const checkLocalStorage = () => {
    try {
      if(localStorage) {
        return true;
      }
      return false;
    } catch(_e) {
      return false
    }
  }

  //do nothing if localStorage is disabled
  const hasLocalStorage = checkLocalStorage();
  if(!hasLocalStorage) {
    return; 
  }

  let localStorageKeyInitial = "_s3tracking";
  let localStorageKey = "_s3trackingLast";
  let formKeys = {first: "first", last: "last"};
  let s3trackingConfig = window._s3trackingConfig || {};
  let state = setLocalStorage(getTrackingData());
  let formElements = (s3trackingConfig.formElements || ".s3-form").split(",");

  const init = () => {
    for (let n = 0; n < formElements.length; n++) {
      waitForMutation(
        document,
        () => (document.querySelectorAll(formElements[n].trim()).length),
        () => (s3tracking(document.querySelectorAll(formElements[n].trim())))
      )
    }
  }

  if(s3trackingConfig.disableFormFields !== true) {
    init();
  }

})();
